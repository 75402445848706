<script setup lang="ts">
import { CardDeck } from "~/common/interfaces/IUser";
import { computed } from "vue";
import { BigNumber } from "ethers";
import useConvert from "~/composables/useConvert";
import { AuctionStatus } from "~/common/enums/auction";
import { CardType, NftSkill as TNftSkill } from "fungi-types";
import { useCardDetails } from "../card-details/use-card-details";

const props = withDefaults(
  defineProps<{
    showCardDetails: boolean;
    item: CardDeck;
    loading?: boolean;
    showFooter?: boolean;
  }>(),
  {
    showCardDetails: true,
    showFooter: true,
  }
);

const { formatUsd, weiToMatic } = useConvert;
const emits = defineEmits<{
  (event: "list", item: CardDeck): void;
  (event: "unlist", item: CardDeck): void;
}>();

const amount = computed(() => {
  const _amount = props.item.auction
    ? BigNumber.from(props.item.auction.lastBidAmount ?? props.item.auction.startedAmount)
    : BigNumber.from(0);
  return formatUsd(weiToMatic(_amount), false);
});

const showAuction = computed(() => {
  return props.item.auction && props.item.auction.status !== AuctionStatus.ENDED;
});

const cardDetails = useCardDetails();
const navigateToCardDetails = (cardToShowTheDetailsFor: TNftSkill) => {
  cardDetails.loadCard(cardToShowTheDetailsFor.tokenId, cardToShowTheDetailsFor);
  cardDetails.showModal = true;
};

const handleClickOnCard = () => {
  if (props.showCardDetails) navigateToCardDetails(props.item);
};
</script>

<template>
  <NftSkill :nft="item" @click="handleClickOnCard">
    <template #footer-data>
      <div class="w-full flex items-center justify-between">
        <div
          :class="`${item.skill}-bg`"
          class="rounded-br-lg px-3 small-text lg:text-sm score-container"
        >
          <p class="font-sans font-semibold">{{ item.score }}</p>
        </div>
        <div
          v-if="showAuction"
          class="flex items-center gap-f1 small-text lg:text-sm 2xl:text-base pr-f1"
        >
          <OvTicker
            :cuid="item.auction!.cuid"
            :end-date="item.auction!.endedAt"
            :status="item.auction!.status"
          />
        </div>
      </div>
      <div v-if="showAuction" class="w-full flex items-center gap-f2 justify-between px-f1">
        <div class="flex flex-1 justify-start items-center overflow-hidden gap-f1 small-text"></div>
        <div
          class="flex items-center gap-f1 justify-end min-w-[50px] d-xs:min-w-[80px] text-sm lg:text-base"
        >
          <p class="font-semibold">{{ amount }}</p>
          <icon-fgc-w-matic class="w-f3 h-f3" />
        </div>
      </div>
    </template>
    <template #cta v-if="showFooter && (!item.card_type || item.card_type === CardType.NftSkills)">
      <button
        v-if="item.auction && item.auction.status === AuctionStatus.ENDED"
        disabled
        class="btn-primary"
        @click.stop
      >
        <span> {{ $t("pages.marketplace.trade.sold") }} </span>
      </button>
      <button v-else-if="item.auction" @click="emits('unlist', item)" class="btn-ghost" @click.stop>
        <span
          v-if="loading || item.auction.status === AuctionStatus.PENDING_TO_BE_EXECUTED"
          class="py-[2px]"
        >
          <icon-fgc-loading />
        </span>
        <span v-else> {{ $t("pages.myDeck.unlist") }} </span>
      </button>

      <button v-else @click="emits('list', item)" class="btn-primary" @click.stop>
        <span v-if="loading" class="py-[2px]">
          <icon-fgc-loading />
        </span>
        <span v-else> {{ $t("pages.myDeck.list") }} </span>
      </button>
    </template>
  </NftSkill>
</template>

<style scoped lang="postcss">
.return-bg {
  @apply bg-skill-return;
}
.service-bg {
  @apply bg-skill-service;
}
.power-bg {
  @apply bg-skill-power;
}
.mental-bg {
  @apply bg-skill-mental;
}
</style>
