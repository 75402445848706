import "overlayscrollbars/overlayscrollbars.css";
import { createApp } from "vue";
import { createPinia } from "pinia";
import Popper from "vue3-popper";
import "mosha-vue-toastify/dist/style.css";
import App from "./App.vue";
import { Router } from "vue-router";
import "@/assets/main.css";
import i18n from "@/modules/i18n";
import { useStoreMarketplace } from "./stores/storeMarketplace";
import { createHead } from "@vueuse/head";
import * as Sentry from "@sentry/vue";
import "unfonts.css";

import { useStoreApp } from "@/stores/storeApp";
import { useStoreUserV2 } from "./stores/storeUserV2";
import router from "./router";

const app = createApp(App);
export const pinia = createPinia();
const head = createHead();

Sentry.init({
  app,
  dsn: "https://a53dec8b3546e57defd94ff61dc66e5a@o4507073322156032.ingest.de.sentry.io/4507073323401296",
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/marketplace.fungiball\.io/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  denyUrls: ["http://localhost:5173"],
});

declare module "pinia" {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

pinia.use(({ store }) => {
  store.router = router;
});

app.use(i18n);
app.use(pinia);
app.use(router);
app.use(head);
app.component("popper", Popper);
app.mount("#app");

let initialized = false;
router.beforeEach(async (to) => {
  const storeApp = useStoreApp();
  const storeMarketplace = useStoreMarketplace();
  const userStore = useStoreUserV2();

  if (to.query.ref) userStore.setRefCode(to.query.ref as string);

  if (!initialized) {
    initialized = true;
    const status = await userStore.init();

    if (userStore.mustRegister && !to.meta.isLegal) return "/register";
    else if (status === 401) {
      userStore.logout().catch(console.error);
      return "/login";
    }
    await storeApp.init();
    await storeMarketplace.init();
  }

  if (to.meta.isAuth && !userStore.isWalletConnected) {
    userStore.logout().catch(console.error);
    return "/login";
  }
});
