import mitt from "mitt";
import { mercureChannel } from "@/application/config";
import { AuctionStatus } from "~/common/enums/auction";
import { NftSkill } from "fungi-types";
import { MarketplaceType } from "~/common/enums/marketplace";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";
import Currency from "~/common/enums/Currency";

export interface BalanceUpdate {
  address: string;
  spendAmount: string;
}

export interface IAuction {
  cuid: string;
  marketType: MarketplaceType;
  lastBidAmount: string;
  lastBidAt: string;
  endedAt: string;
  countOrders: number;
  status: AuctionStatus;
  card?: NftSkill;
}

export interface AuctionUpdate {
  auction: IAuction;
  bidder: {
    username: string;
    cuid: string;
  };
}

export interface AuctionEnd {
  cuid: string;
  status: AuctionStatus;
  winner: {
    userId: number;
    username: string;
    address: string;
  };
}

export interface CantBidWithStripe {
  userCuid: string;
  auction: IAuctionItem;
  bidder: {
    username: string;
    cuid: string;
  };
}

export interface NewPolRates {
  [Currency.EUR]: number;
  [Currency.USD]: number;
}

export enum MercureEventType {
  MATIC_PRICE = "update-price-matic",
  AUCTION_UPDATE = "update-auction",
  AUCTION_NEW = "new-auction-listing",
  AUCTION_END = "end-auction",
  UPDATE_BALANCE = "update-balance",
  CANT_BID_WITH_STRIPE = "cant-bid-with-stripe",
  NEW_POL_RATES = "pol-rates",
}

export type MercureEvents = {
  "update-price-matic": { price: number };
  "update-auction": AuctionUpdate;
  "new-auction-listing": any;
  "end-auction": AuctionEnd;
  "update-balance": BalanceUpdate;
  "cant-bid-with-stripe": CantBidWithStripe;
  "pol-rates": NewPolRates;
};

export class MercureService {
  private static instance: MercureService;
  public emitter = mitt<MercureEvents>();
  hubUrl: URL;

  private constructor() {
    this.hubUrl = new URL(import.meta.env.VITE_MERCURE_URL);
    this.subscribeAll();
    this.routerAction();
  }

  /**
   * The static method that controls the access to the singleton instance.
   *
   * This implementation lets you subclass the Singleton class while keeping
   * just one instance of each subclass around.
   */
  public static getInstance(): MercureService {
    if (!MercureService.instance) {
      MercureService.instance = new MercureService();
    }

    return MercureService.instance;
  }

  private subscribeAll() {
    this.hubUrl.searchParams.append("topic", `/${mercureChannel}/update-auction`);
    this.hubUrl.searchParams.append("topic", `/${mercureChannel}/update-price-matic`);
    this.hubUrl.searchParams.append("topic", `/${mercureChannel}/new-auction`);
    this.hubUrl.searchParams.append("topic", `/${mercureChannel}/end-auction`);
    this.hubUrl.searchParams.append("topic", `/${mercureChannel}/update-balance`);
    this.hubUrl.searchParams.append("topic", `/${mercureChannel}/cant-bid-with-stripe`);
    this.hubUrl.searchParams.append("topic", `/${mercureChannel}/pol-rates`);
  }

  private routerAction() {
    const eventSource = new EventSource(this.hubUrl);
    eventSource.onmessage = (event) => {
      const payload = JSON.parse(event.data);
      const type = payload.type as MercureEventType;
      this.dispatch(type, payload);
    };
  }

  private dispatch(type: MercureEventType, payload: any) {
    switch (type) {
      case MercureEventType.MATIC_PRICE:
        this.emitter.emit(type, payload.data as { price: number });
        break;

      case MercureEventType.AUCTION_UPDATE:
        this.emitter.emit(type, payload.data as AuctionUpdate);
        break;

      case MercureEventType.AUCTION_NEW:
        this.emitter.emit(type, payload.data as any);
        break;

      case MercureEventType.AUCTION_END:
        this.emitter.emit(type, payload.data as AuctionEnd);
        break;

      case MercureEventType.UPDATE_BALANCE:
        this.emitter.emit(type, payload.data as BalanceUpdate);
        break;

      case MercureEventType.CANT_BID_WITH_STRIPE:
        this.emitter.emit(type, payload.data as CantBidWithStripe);
        break;

      case MercureEventType.NEW_POL_RATES:
        this.emitter.emit(type, payload.data as NewPolRates);
        break;

      default:
        break;
    }

    // call service for update the store auction with cuid
  }
}
