import { computed, ref } from "vue";
import { allowWMaticMinAmount } from "~/application/config";
import { useStoreUserV2 } from "@/stores/storeUserV2";
import { defineStore } from "pinia";
import { MercureEventType, MercureService } from "~/services/mercureService";
import { toEther } from "~/composables/web3Utils";
import { BigNumber } from "ethers";
import mitt from "mitt";
import { IAuctionItem } from "~/common/interfaces/IMarketplace";

export type StoreAppEvent = {
  notif: { type: NotifType; auctionItem: IAuctionItem; text?: string };
  tick: number;
};

export enum NotifType {
  TOPBID = "topbid",
  OUTBID = "outbid",
  OVERBID = "overbid",
  WIN = "win",
}

export const useStoreApp = defineStore("app-store", () => {
  // Interval
  const debug = ref(false);
  let initialized = false;

  // Status
  const actionInProgress = ref(false);

  // Modals and display status controllers
  const showNeedMatic = ref(false);
  const showSwapModal = ref(false);
  const showBidModal = ref(false);
  const showListModal = ref(false);
  const showBuyModal = ref(false);
  const showDepositModal = ref(false);
  const showTransferModal = ref(false);

  const mercureService = MercureService.getInstance();

  // Intervals
  const secondsTickerInterval = ref(0);
  const refreshInterval = ref({});
  const emitter = mitt<StoreAppEvent>();

  const showSwapButton = computed(() => {
    const storeUser = useStoreUserV2();

    return (
      storeUser.isWalletConnected &&
      (storeUser.currentMaticBalance > 0.01 || storeUser.currentWMaticBalance > 0.01)
    );
  });

  const showAuthorizeWMaticButton = computed(() => {
    const storeUser = useStoreUserV2();

    return (
      storeUser.isWalletConnected &&
      storeUser.currentWMaticBalance > 0.01 &&
      storeUser.allowedWMaticAmount < allowWMaticMinAmount
    );
  });

  async function init() {
    if (initialized) return;

    window.clearInterval(secondsTickerInterval.value);

    //TODO/REFACTOR: Already init in storeUserV2 ?
    mercureService.emitter.on(MercureEventType.UPDATE_BALANCE, (event) => {
      const storeUser = useStoreUserV2();
      if (storeUser.walletAddress === event.address) {
        storeUser.ongoingExpenses = toEther(BigNumber.from(event.spendAmount));
      }
    });

    secondsTickerInterval.value = window.setInterval(() => {
      emitter.emit("tick", Date.now());
    }, 1_000);

    initialized = true;
  }

  function setActionInProgress(value: boolean) {
    actionInProgress.value = value;
  }

  function setBidModalState(value: boolean) {
    showBidModal.value = value;
  }

  function displayNotif(type: NotifType, auctionItem: IAuctionItem, text?: string) {
    emitter.emit("notif", { type, auctionItem, text });
  }

  return {
    debug,
    actionInProgress,
    showNeedMatic,
    showSwapModal,
    showBidModal,
    showListModal,
    showBuyModal,
    showTransferModal,
    showDepositModal,
    mercureService,
    refreshInterval,
    emitter,
    showSwapButton,
    showAuthorizeWMaticButton,
    init,
    setActionInProgress,
    setBidModalState,
    displayNotif,
  };
});
