<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { useStoreApp } from "@/stores/storeApp";
import router from "~/router";
import Trans from "~/modules/translations";
import { useCardDetails } from "~/components/card-details/use-card-details";
import Modal from "~/components/common/Modal.vue";
import ModalDeposit from "~/components/modals/ModalDeposit.vue";
import { useStripeStore } from "~/composables/useStripe";
import { useStoreMarketplace } from "~/stores/storeMarketplace";

const { t } = useI18n();
const storeApp = useStoreApp();
const cardDetails = useCardDetails();

function needMaticGotoMyAccount() {
  router.push(Trans.i18nRoute({ name: "MyAccount" }));
  storeApp.showNeedMatic = false;
}

const stripe = useStripeStore();
const marketplace = useStoreMarketplace();

function handleBidModalClosing() {
  if (stripe.checkoutSessionInProgress) {
    stripe.cancelCheckoutSession();
    marketplace.bidInProgress = false;
  } else {
    storeApp.showBidModal = false;
  }
}
</script>

<template>
  <OnboardingModalContainer />
  <Modal
    :show="storeApp.showBidModal"
    @closed="handleBidModalClosing"
    :background="false"
    full-content
    :z="100"
  >
    <ModalBid />
  </Modal>
  <Modal :show="storeApp.showNeedMatic" @closed="storeApp.showNeedMatic = false">
    <div class="flex flex-col items-center gap-6">
      <icon-clarity:warning-line class="h-8 w-8 text-orange-40" />
      <h1 class="text-2xl uppercase font-display">{{ t("modals.needMatic.title") }}</h1>
      <p class="flex flex-wrap max-w-[400px] whitespace-pre-wrap">
        {{ t("modals.needMatic.desc") }}
      </p>
      <button class="btn-primary" @click="needMaticGotoMyAccount">
        {{ t("modals.needMatic.linktomyaccount") }}
      </button>
    </div>
  </Modal>
  <Modal
    :show="storeApp.showListModal"
    @closed="storeApp.showListModal = false"
    :background="false"
    full-content
    :has-close-button="false"
  >
    <ModalList @closed="storeApp.showListModal = false" />
  </Modal>
  <Modal
    :show="storeApp.showBuyModal"
    @closed="storeApp.showBuyModal = false"
    :background="false"
    full-content
    :z="100"
  >
    <ModalBuy />
  </Modal>
  <CardDetails div v-if="cardDetails.showModal" is-modal />
  <ModalDeposit v-model:show="storeApp.showDepositModal" />
</template>
