<script setup lang="ts">
import { computed, defineModel, defineProps, onBeforeMount, ref, toRefs, watch } from "vue";
import { BigNumber } from "ethers";
import Currency from "~/common/enums/Currency";
import usePOLPrice from "~/stores/POLprice";
import { roundToDecimals } from "~/utils/math";

const model = defineModel<number>();
const errorModel = defineModel<boolean>("error");
const props = defineProps<{
  minPolAmount: BigNumber;
  currency: Currency;
  defaultToMin: boolean;
}>();
const { currency, minPolAmount } = toRefs(props);
const polPrice = usePOLPrice();

const inputValue = ref("");

// This is a "hotfix" to avoid amounts being too close to the actual min amount
// This enables a better UX by avoiding back and forth between the backend and the input
const fixedIncrease = 0.02;

function computeRoundedMinFiatAmountFromWeiAndCurrency(wei: BigNumber, currency: Currency) {
  return roundToDecimals(polPrice.weiToCurrency(wei, currency), 2);
}

onBeforeMount(() => {
  errorModel.value = false;

  if (props.defaultToMin) {
    const initialValue =
      computeRoundedMinFiatAmountFromWeiAndCurrency(props.minPolAmount, props.currency) +
      fixedIncrease;
    model.value = initialValue;
    inputValue.value = initialValue.toFixed(2);
  }
});

watch(inputValue, (newValue) => {
  const numberValue = parseFloat(newValue.replace(",", "."));
  if (!isNaN(numberValue)) {
    model.value = numberValue;
  } else {
    model.value = NaN; // Set model to NaN if invalid
  }
});

watch(currency, (newCurrency) => {
  const newMinAmount =
    computeRoundedMinFiatAmountFromWeiAndCurrency(minPolAmount.value, newCurrency) + fixedIncrease;

  model.value = newMinAmount;
  inputValue.value = newMinAmount.toFixed(2);
});

const minFiatAmount = computed(() => {
  return computeRoundedMinFiatAmountFromWeiAndCurrency(minPolAmount.value, currency.value);
});

const isTooLow = computed(() => {
  return model.value == null || isNaN(+model.value) || +model.value < minFiatAmount.value;
});

const hasFormatError = computed(() => {
  // Check if the input value is a number with up to 2 decimals and a dot or comma separator
  const inputRegex = /^\d+([.,]\d{1,2})?$/;

  return !inputRegex.test(inputValue.value?.toString() ?? "");
});

watch([isTooLow, hasFormatError], ([isTooLow, hasFormatError]) => {
  errorModel.value = isTooLow || hasFormatError;
});

const equivalentPolAmount = computed(() => {
  return roundToDecimals(polPrice.currencyToPol(model.value ?? 0, currency.value), 3);
});
</script>

<template>
  <fieldset class="flex flex-col w-full items-start gap-f2">
    <div class="flex flex-row relative items-center justify-end w-full">
      <icon-clarity:dollar-solid
        v-if="props.currency === Currency.USD"
        class="inline-icon absolute left-2 text-grey"
      />
      <icon-clarity:euro-solid
        v-else-if="props.currency === Currency.EUR"
        class="inline-icon absolute left-2 text-grey"
      />
      <input type="text" v-model="inputValue" class="form-input-fg w-full pl-9" />
    </div>
    <div class="flex items-center gap-f2">
      <span class="text-grey" v-if="!hasFormatError">
        {{ isNaN(equivalentPolAmount) ? "" : `≈ ${equivalentPolAmount} POL` }}
      </span>
      <div v-if="hasFormatError">
        <slot name="format-error" />
      </div>
      <div v-else-if="isTooLow">
        <slot name="too-low" />
      </div>
    </div>
  </fieldset>
</template>
