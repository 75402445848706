<script setup lang="ts">
import { computed, ref } from "vue";
import Modal from "@/components/modals/ModalContainer.vue";
import { useRamp } from "@/composables/useRamp";
import { useMediaQuery } from "~/composables/useMediaQuery";
import { useTransak } from "@/composables/useTransak";
import { isAfter, parseISO } from "date-fns";

import LogoTransak from "/logo-transak.svg?url";
import LogoRamp from "/logo-ramp.svg?url";

const isMobile = useMediaQuery("(max-width: 800px)");
const device = computed(() => (isMobile.value ? "mobile" : "desktop"));

const { showOverlay: showRampOverlay } = useRamp();
const { showOverlay: showTransakOverlay } = useTransak();

const props = defineProps<{
  show: boolean;
}>();

const showRampOption = computed(() => {
  const currentDate = new Date();
  const migrationDate = parseISO("2021-09-04T08:00:00");
  return isAfter(currentDate, migrationDate);
});

const emit = defineEmits(["update:show"]);

const handleOnClose = () => {
  emit("update:show", false);
};

const selectRamp = () => {
  showRampOverlay(device.value);
  handleOnClose();
};

const selectTransak = () => {
  showTransakOverlay(device.value);
  handleOnClose();
};
</script>

<template>
  <Modal :show="show" @onClose="handleOnClose" :title="$t('modals.buyMatic.title')">
    <div class="flex flex-col gap-4">
      <p class="whitespace-pre-line mb-2 text-sm text-greyLight text-justify">
        {{ $t("modals.buyMatic.description") }}
      </p>
      <div class="flex flex-col gap-4">
        <button
          v-if="showRampOption"
          class="bg-gray-800 p-3 rounded-md hover:bg-gray-700 text-left"
          @click="selectRamp"
        >
          <img :src="LogoRamp" alt="Ramp" class="w-6 h-6 mr-2 inline-block" />
          {{ $t("modals.buyMatic.useRamp") }}
        </button>
        <button
          class="bg-gray-800 p-3 rounded-md hover:bg-gray-700 text-left"
          @click="selectTransak"
        >
          <img :src="LogoTransak" alt="Ramp" class="w-6 h-6 mr-2 inline-block" />
          {{ $t("modals.buyMatic.useTransak") }}
        </button>
      </div>
    </div>
  </Modal>
</template>

<style scoped>
/* Add any additional styles here */
</style>
