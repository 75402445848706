<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useStoreDeck } from "~/stores/storeDeck";
import { computed, ref } from "vue";
import { BigNumber } from "ethers";
import useConvert from "~/composables/useConvert";
import { MarketplaceApi } from "~/api/marketplace";
import { MarketplaceType } from "~/common/enums/marketplace";
import { useStoreUserV2 } from "~/stores/storeUserV2";
import { SeaportService } from "~/services/seaportService";
import { createToast } from "mosha-vue-toastify";
import { displayError } from "~/utils/errors";
import { useI18n } from "vue-i18n";

const { maticToWei } = useConvert;
const storeDeck = useStoreDeck();
const storeUser = useStoreUserV2();
const bidAmount = ref(maticToWei(1));
const minAmount = ref(maticToWei(1));
const decimals = ref(3);
const { selected } = storeToRefs(storeDeck);
const marketplaceApi = new MarketplaceApi(MarketplaceType.SECONDARY);
const loading = ref(false);
import { userToken } from "~/utils/localStorage";
const { t } = useI18n();

const emits = defineEmits<{
  (event: "closed"): void;
}>();

const amountTooLow = computed(() => bidAmount.value.lt(minAmount.value));

function updateBidAmount(e: BigNumber) {
  bidAmount.value = e;
}

async function list() {
  try {
    loading.value = true;
    const seaportService = new SeaportService(storeUser.wallet.signer);
    const params = await marketplaceApi.listingParams(
      selected.value.tokenId,
      bidAmount.value.toString(),
      userToken.get(),
      selected.value.card_type
    );
    const orderWithCounter = await seaportService.getOrderSignature(
      params,
      storeUser.walletAddress
    );
    const { success, auction } = await marketplaceApi.list(
      orderWithCounter,
      selected.value.tokenId,
      userToken.get(),
      selected.value.card_type
    );
    if (success) {
      createToast(t("modals.list.messages.success"), {
        type: "success",
      });
      storeDeck.updateItem(selected.value.tokenId, {
        card_type: selected.value.card_type,
        auction: auction,
      });
    } else {
      displayError(t("modals.list.messages.error-unlist"));
    }
    emits("closed");
    loading.value = false;
  } catch (e: any) {
    if (e.response?.data?.code === "E_NFT_INUSE") {
      displayError(t("modals.list.messages.error-in-use"));
    } else {
      displayError(t("modals.list.messages.error"));
    }
    loading.value = false;
  }
}
</script>

<template>
  <div class="grid grid-cols-3 gap-4 text-white md:max-w-[60vw]">
    <div class="hidden md:flex gap-f4 mx-auto bg-dark-blury gradient-border bg-left">
      <div class="flex flex-col">
        <NftSkill :nft="selected" :background="false" />
        <span class="text-center font-display uppercase title-xs-baseline"
          >{{ selected.firstName }} {{ selected.lastName }}</span
        >
      </div>
    </div>
    <div
      class="flex flex-col justify-around gap-2 md:gap-f3 col-span-3 md:col-span-2 bg-dark-blury before:z-[-1] shadow-xl py-8 px-f4 rounded-2xl md:rounded-l-none md:before:rounded-r-2xl md:gradient-border"
    >
      <div class="flex items-center justify-between">
        <h1 class="title-sm d-s:title-md font-display uppercase">{{ $t("modals.list.title") }}</h1>
        <button>
          <icon-mingcute:close-fill @click="emits('closed')" class="w-f4 h-f4" />
        </button>
      </div>
      <div class="flex flex-col gap-f1 px-f3 md:hidden items-center justify-center">
        <div class="flex flex-col w-4/6">
          <NftSkill :nft="selected" :background="false" />
          <span class="text-center font-display uppercase title-xs-baseline"
            >{{ selected.firstName }} {{ selected.lastName }}</span
          >
        </div>
      </div>
      <div class="flex flex-col gap-f3">
        <span class="font-bold uppercase">{{ $t("modals.list.total") }}</span>
        <ConverterInput
          :value="bidAmount"
          :min="minAmount"
          @wei-amount="updateBidAmount"
          :hideConversion="false"
          :decimals="decimals"
          :canSwitchMode="false"
        >
          <template #msg v-if="amountTooLow">
            <span class="text-error">{{ $t("modals.bid.toolow") }}</span>
          </template>
        </ConverterInput>
      </div>
      <button @click="list" class="btn-primary">
        <icon-fgc-loading v-if="loading" />
        <span v-else>{{ $t("modals.list.confirm") }}</span>
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg-left {
  @apply py-f3 px-f1 rounded-l-2xl before:rounded-l-2xl before:z-[-1] shadow-xl;
}
</style>
