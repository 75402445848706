<script setup lang="ts">
import { ref } from "vue";

import { computePosition, flip, shift, offset, arrow, Placement } from "@floating-ui/dom";

const reference = ref<HTMLElement>();
const floating = ref<HTMLElement>();
const floatingArrow = ref<HTMLElement>();
const isHidden = ref(true);

const props = withDefaults(
  defineProps<{
    placement?: Placement;
    theme?: "dark" | "light";
    text?: string;
    offset?: number;
    maxWidth?: number;
  }>(),
  {
    content: "",
    position: "bottom",
    theme: "light",
    offset: 8,
    maxWidth: 200,
  }
);

const updatePosition = async () => {
  if (reference.value && floating.value && floatingArrow.value) {
    const { x, y, middlewareData, placement } = await computePosition(
      reference.value,
      floating.value,
      {
        placement: props.placement,
        middleware: [
          offset(props.offset),
          flip(),
          shift({ padding: 5 }),
          arrow({ element: floatingArrow.value }),
        ],
      }
    );

    Object.assign(floating.value.style, {
      left: `${x}px`,
      top: `${y}px`,
    });

    let arrowX, arrowY;

    if (middlewareData.arrow) {
      ({ x: arrowX, y: arrowY } = middlewareData.arrow);
    }

    const opposedSide: any = {
      left: "right",
      right: "left",
      bottom: "top",
      top: "bottom",
    }[placement.split("-")[0]];

    Object.assign(floatingArrow.value.style, {
      left: arrowX ? `${arrowX}px` : "",
      top: arrowY ? `${arrowY}px` : "",
      bottom: "",
      right: "",
      [opposedSide]: "-4px",
    });
  }
};

const hide = () => {
  isHidden.value = true;
};

const show = () => {
  isHidden.value = false;
  updatePosition();
};
</script>

<template>
  <div class="font-normal normal-case font-sans">
    <div ref="reference" @blur="hide" @focus="show" @mouseenter="show" @mouseleave="hide">
      <slot />
    </div>
    <div
      ref="floating"
      :class="[
        'absolute top-0 left-0 z-50 bg-white text-xs text-slate-900 px-3 py-1.5 rounded-md cursor-default',
        isHidden && 'hidden',
        `max-w-96`,
      ]"
    >
      {{ props.text }}
      <slot name="content"> </slot>
      <div ref="floatingArrow" class="absolute bg-white h-[8px] w-[8px] rotate-45"></div>
    </div>
  </div>
</template>

<style scoped></style>
