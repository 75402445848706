import { BigNumber } from "ethers";
import { MarketplaceType } from "~/common/enums/marketplace";

export type PriceHistoryPoint = {
  price: BigNumber;
  timestamp: Date;
  type: MarketplaceType;
};

export type PriceHistoryPointDto = Pick<PriceHistoryPoint, "type"> & {
  price: string;
  timestamp: string;
};

export type PriceHistory = {
  params: Record<string, unknown>;
  data: PriceHistoryPoint[];
  stats: {
    dataPoints: number;
    average: BigNumber;
    min: BigNumber;
    max: BigNumber;
  };
};

export type PriceHistoryDto = Pick<PriceHistory, "params"> & {
  data: PriceHistoryPointDto[];
  stats: {
    data_points: number;
    average: string;
    min: string;
    max: string;
  };
};

export function mapPriceHistoryPointDtoToPriceHistoryPoint(
  dto: PriceHistoryPointDto
): PriceHistoryPoint {
  return {
    type: dto.type,
    price: BigNumber.from(dto.price),
    timestamp: new Date(dto.timestamp),
  };
}

export function mapPriceHistoryDtoToPriceHistory(dto: PriceHistoryDto): PriceHistory {
  const data = dto.data.map(mapPriceHistoryPointDtoToPriceHistoryPoint);

  return {
    data,
    params: dto.params,
    stats: {
      dataPoints: dto.stats.data_points,
      average: dto.stats.average !== "N/A" ? BigNumber.from(dto.stats.average) : BigNumber.from(0),
      min: dto.stats.min !== "N/A" ? BigNumber.from(dto.stats.min) : BigNumber.from(0),
      max: dto.stats.max !== "N/A" ? BigNumber.from(dto.stats.max) : BigNumber.from(0),
    },
  };
}
