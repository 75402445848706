<script lang="ts" setup>
import { Power1, gsap } from "gsap";
import { onMounted, onBeforeUnmount, computed, ref } from "vue";
import { AuctionStatus } from "~/common/enums/auction";
import { useStoreApp } from "~/stores/storeApp";
import { format } from "date-fns";
import { fr, enUS } from "date-fns/locale";
import { useI18n } from "vue-i18n";

// Constants
const MILLISECONDS_PER_SECOND = 1000;
const SECONDS_PER_MINUTE = 60;
const MINUTES_PER_HOUR = 60;
const HOURS_PER_DAY = 24;
const RED_TIME = SECONDS_PER_MINUTE * MILLISECONDS_PER_SECOND;

// Reactive Variables
const days = ref(0);
const hours = ref(0);
const minutes = ref(0);
const seconds = ref(0);
const prevTime = ref(0);
const lastTime = ref(false);
const auctionEnded = ref(false);
const displayBall = ref(false);

const locale = useI18n().locale;

// Props and Events
const props = defineProps<{
  cuid?: string | number;
  title?: string;
  endDate?: string | Date | null;
  status: AuctionStatus;
}>();
const emits = defineEmits<{ (event: "time-out"): void; (event: "outbid"): void }>();

// Computed Variables
const end = computed(() => {
  if (props.endDate) {
    return new Date(props.endDate);
  } else {
    return new Date();
  }
});

/**
 * Perform the red animation
 */
function animRed() {
  gsap.fromTo(
    `#clock${props.cuid}`,
    { scale: 1.3 },
    {
      duration: 0.12,
      scale: 1,
      ease: Power1.easeInOut,
    }
  );
}

/**
 * Perform the end animation and emit time-out event
 */
function animEnd() {
  displayBall.value = true;
  gsap.delayedCall(Math.random() * 3 + 1, () => {
    displayBall.value = false;
    emits("time-out");
  });
}

/**
 * Calculate the time units (days, hours, minutes, seconds) from a given distance in milliseconds
 * @param distance the time distance in milliseconds
 */
function calculateTimeUnits(distance: number) {
  days.value = Math.floor(
    distance / (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR * HOURS_PER_DAY)
  );
  hours.value = Math.min(
    99,
    Math.floor(distance / (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR))
  );
  minutes.value = Math.floor(
    (distance % (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE * MINUTES_PER_HOUR)) /
      (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE)
  );
  seconds.value = Math.floor(
    (distance % (MILLISECONDS_PER_SECOND * SECONDS_PER_MINUTE)) / MILLISECONDS_PER_SECOND
  );
}

/**
 * Update the countdown time every second, perform animations and emit events when necessary
 */
function updateTime(now: number) {
  let current = end.value.getTime();
  const distance = current - now;

  lastTime.value = distance < RED_TIME;
  auctionEnded.value = distance <= 0;

  prevTime.value = current;

  calculateTimeUnits(distance);

  if (auctionEnded.value) {
    gsap.killTweensOf(`#clock${props.cuid}`);
    useStoreApp().emitter.off("tick", updateTime);
    animEnd();
    return;
  }
  if (lastTime.value) {
    animRed();
  }
}

// Lifecycle Hooks
onMounted(() => {
  if (props.status === AuctionStatus.ENDED || props.status === AuctionStatus.CANCELLED) {
    auctionEnded.value = true;
    lastTime.value = true;
  } else {
    useStoreApp().emitter.on("tick", updateTime);
  }
});

onBeforeUnmount(() => {
  useStoreApp().emitter.off("tick", updateTime);
});

// Computed for display
const formattedTime = computed(() => {
  return [
    String(hours.value).padStart(2, "0"),
    String(minutes.value).padStart(2, "0"),
    String(seconds.value).padStart(2, "0"),
  ];
});
</script>

<template>
  <div class="flex items-center gap-1" v-if="endDate">
    <icon-fg-timer
      v-if="!displayBall && !auctionEnded"
      :id="`clock${cuid}`"
      class="mb-px"
      :class="lastTime ? 'text-yellow' : 'text-white'"
    />

    <div class="flex flex-col">
      <label v-if="title" class="uppercase text-grey font-display">{{ title }}</label>

      <div v-if="auctionEnded">
        <icon-fgc-loading v-if="displayBall" />
        <span v-else class="small-text">{{ $t("card.ended") }}</span>
      </div>

      <div
        v-else
        class="flex items-center text-right text-white gap-1 max-t-l:mt-[1px]"
        :class="lastTime ? 'text-yellow' : 'text-white'"
      >
        <Tooltip
          :text="
            format(
              endDate!,
              locale === 'fr' ? `dd LLLL yyyy à HH:mm:ss` : `LLLL do',' yyyy 'at' hh:mm:ss aaa`,
              {
                locale: locale === 'fr' ? fr : enUS,
              }
            )
          "
          placement="top"
        >
          <span class="text-right" v-if="formattedTime[0] !== '00'"> {{ formattedTime[0] }}h </span>
          <span class="text-right" v-if="formattedTime[0] !== '00' || formattedTime[1] !== '00'">
            {{ formattedTime[1] }}m
          </span>
          <span class="w-[22px]"> {{ formattedTime[2] }}s </span>
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
